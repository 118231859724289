import React, { PropsWithChildren } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Spinner } from '@components/atoms/Spinner/Spinner';
import { IconPlayTvguide } from '@assets/icon-play-tvguide';

interface ButtonProps extends PropsWithChildren, PropsWithClassProps<'button'> {
  hasAction?: boolean;
  title?: string;
  isLoading?: boolean;
  onAction?: () => void;
  link?: string;
  openInTab?: boolean;
  buttonStyle?: 'primary' | 'secondary' | 'tertiary' | 'viewLink';
}

export const Button = ({
  title,
  hasAction,
  isLoading,
  link,
  onAction,
  classProps,
  openInTab,
  buttonStyle = 'secondary',
  children,
}: ButtonProps) => {
  const buttonStyles = {
    primary: 'button-primary',
    secondary: 'button-secondary',
    tertiary: 'button-tertiary',
    viewLink: 'button-view-link',
  };

  const mainStyle = twMerge('button mt-6 items-center gap-2', buttonStyles[buttonStyle], classProps?.button);
  return hasAction ? (
    <button className={mainStyle} onClick={onAction}>
      {isLoading && <Spinner iconSize="1em" />}
      {children || title}
    </button>
  ) : (
    link && (
      <a href={link} className={mainStyle} target={openInTab && '_blank'} rel={openInTab && 'noopener noreferrer'}>
        {buttonStyle === 'viewLink' && <IconPlayTvguide />}
        {children || title || 'Weiter'}
      </a>
    )
  );
};
